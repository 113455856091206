@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Advent Pro', sans-serif; /*Old font-family was Poppins*/
}

:root {
  --dark-color: #252b48;
  --light-color: #a367f1;
  --white-color: #ffffff;
  --gray-color: #2b2b2b;
  --body-color: rgba(244, 244, 244, 0.79);
  --shadow-color: #d2beee;
}

body {
  background-color: var(--body-color);
}

html {
  scroll-behavior: smooth;
}

.container {
  width: 90%;
  margin: 0 auto;
  padding-top: 4rem;
  /* display: flex; */
}

@media screen and (min-width: 1880px) {
  .container {
    width: 68%;
  }
}

@media screen and (min-width: 1880px) {
  .container {
    width: 68%;
  }

  .slider-text {
    left: 14.5%;
  }
}

@media screen and (max-width: 586px) {
  .container {
    padding-top: 3rem;
  }
}

h1 {
  font-size: 48px;
  font-weight: 600;
}

h2 {
  font-weight: 600;
  font-size: 40px;
}

h3 {
  font-weight: 600;
  font-size: 22px;
}

p {
  font-weight: 400;
  font-size: 20px;
}

button {
  border: none;
  background-color: var(--light-color);
  padding: 0.4rem 1rem;
  color: var(--white-color);
  border-radius: 4px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
}

button:hover {
  box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

a {
  text-decoration: none;
  color: unset;
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 586px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 478px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 360px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  p {
    font-size: 1.2rem;
  }
}

/* NAVBAR CSS */
.navbar {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: var(--white-color);
}

.navbar .container {
  padding-top: 0;
}

.navbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo-div h2 {
  color: #a367f1;
  width: 100%;
}

.nav-listings {
  width: 50%;
}

.ul-nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.ul-nav li {
  padding: 0.3rem 0.8rem;
  list-style: none;
  cursor: pointer;
}

/* Add this to your CSS */
.services-dropdown {
  position: relative; /* Position relative for absolute positioning of submenu */
}

.submenu-wrapper {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 180px;
  border-radius: 8px;
  z-index: 10;
}

.services-dropdown:hover .submenu-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.submenu li {
  padding: 5px 0;
  text-align: center;
}

.submenu li:hover {
  color: var(--light-color);
}

/* FOOTER CSS */
.footer {
  background: linear-gradient(
    137deg,
    var(--dark-color) 0%,
    var(--light-color) 100%
  );
  padding-bottom: 4rem;
  margin-top: 10rem;
}

.footer-wrapper {
  width: 100%;
}

.footer-top {
  background-color: var(--white-color);
  width: 100%;
  border-radius: 4px;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -9rem;
  position: relative;
  z-index: 1;
}

.git {
  margin-bottom: 0.4rem;
}

.git h4 {
  background: var(
    --gradient,
    linear-gradient(167deg, var(--dark-color) 0%, var(--gray-color) 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.divider img {
  width: 85px;
}

.paragraph-divider {
  margin-bottom: 1.2rem;
}

.divider {
  margin-bottom: 0.4rem;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
}

.bottom-content-center {
  width: 65%;
}

.bottom-content h5 {
  color: var(--white-color);
  font-size: medium;
}

.bottom-content p {
  width: 70%;
  color: var(--white-color);
  /* font-size: 12px; */
  margin: 0.6rem 0;
}

.footer-icons {
  display: flex;
}

.footer-icons svg {
  font-size: 21px;
  color: var(--white-color);
  cursor: pointer;
  margin-right: 0.4rem;
  border-radius: 1px;
}

.services-ul {
  margin: 0.6rem 0;
}

.services-ul li {
  color: var(--white-color);
  font-size: 12px;
}

.icon-info {
  display: flex;
  margin: 0.6rem 0;
  align-items: center;
}

.icon-info p {
  margin-left: 0.6rem;
}

.icon-info svg {
  color: var(--white-color);
}

/* HOME CSS */
.home-banner {
  height: 100vh;
}

.banner-slider {
  position: relative;
}

.banner-slider img {
  width: 100%;
  height: auto; /* Set height to auto to maintain aspect ratio */
}

@media screen and (min-width: 1200px) {
  .banner-slider img {
    height: 750px; /* Adjust height for larger screens */
  }
}

@media screen and (max-width: 768px) {
  .banner-slider img {
    height: 500px; /* Adjust height for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .banner-slider img {
    height: 350px; /* Adjust height for even smaller screens */
  }
}

.slider-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  color: white;
  width: 50%;
}

.slider-text h3 {
  color: #a367f1;
  font-size: 84pt;
  font-weight: 800;
  text-align: center; /* Center align the text horizontally */
  position: relative;
  bottom: -30%; /* Align the text to the bottom */
  left: 50%; /* Center align horizontally */
  line-height: 1.0; /* Reduced line spacing */
  transform: translateX(-15%); /* Center align horizontally */
}

.color-text {
  color: var(--light-color);
}

.home-banner-container {
  padding-top: 0;
  position: relative;
}

.slick-dots li button:before {
  color: var(--white-color) !important;
  opacity: 0.2 !important;
}

.slick-dots li.slick-active button:before {
  color: var(--white-color);
  opacity: 1 !important;
}

.slider-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.slider-wrapper img {
  object-fit: cover;
}

@media screen and (min-width: 1880px) {
  .slider-text {
    left: 14.5%;
  }
}

.section-heading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.section-heading h2 {
  text-align: center;
}

.section-heading p {
  text-align: center;
  width: 70%;
  margin-top: 0.4rem;
  font-size: 1.2rem;
}

.divider-shape {
  padding: 0.2rem;
  height: 2px;
  width: 40px;
  background-color: var(--light-color);
  margin-top: 0.4rem;
  margin-bottom: 0.8rem;
}

.home-about-bottom {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-about-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: var(--white-color);
  max-width: 25vw;
}

.home-about-item p {
  text-align: center;
}

.home-about-item h3 {
  text-align: center;
  padding-bottom: 1rem;
}

.home-about-item:hover {
  background-color: var(--light-color);
  cursor: pointer;
}

.mr {
  margin-right: 0.6rem;
}


.home-products-wrapper {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.home-product-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  border-radius: 8px;
  box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.25);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  background-color: #2b2b2b7e;
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 768px) {
  .home-product-item {
    width: 150px;
    height: 150px;
  }
}

.home-product-item:hover {
  background-color: #2b2b2bcb;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.hpi-1 {
  background-image: url(../src/assets/home-produtcs/deicing-salt.webp);
}

.hpi-2 {
  background-image: url(../src/assets/home-produtcs/single-wash-raw.webp);
}

.hpi-3 {
  background-image: url(../src/assets/home-produtcs/double-wash-raw.webp);
}

.hpi-4 {
  background-image: url(../src/assets/home-produtcs/edible-salt.webp);
}

.hpi-5 {
  background-image: url(../src/assets/home-produtcs/tablet-salt.webp);
}

.hpi-6 {
  background-image: url(../src/assets/home-produtcs/dishwasher-salt.webp);
}

.hpi-7 {
  background-image: url(../src/assets/home-produtcs/bath-salt.webp);
}

.hpi-8 {
  background-image: url(../src/assets/home-produtcs/pool-salt.webp);
}

.home-product-info h3 {
  color: var(--white-color);
  text-align: center;
}

.mt {
  margin-top: 0.6rem;
}

.home-sizes-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-sizes-left {
  width: 100%;
  margin-right: 1.5rem;
}

.home-sizes-left p {
  text-align: justify;
}

.home-sizes-right {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.home-size-item {
  padding: 1.4rem;
  background-color: var(--white-color);
  border-radius: 8px;
  box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.25);
  width: 180px;
  height: 220px;
}

@media (max-width: 1290px) {
  .home-sizes-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .home-sizes-left p {
    text-align: center;
    margin-bottom: 0.8rem
  }

  .home-size-item {
    padding: 1.4rem;
    background-color: var(--white-color);
    border-radius: 8px;
    box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.25);
    width: 33%;
    height: 50%;
}
}

.home-size-item:hover {
  background-color: var(--light-color);
  cursor: pointer;
}

.home-size-item svg {
  font-size: 28px;
  margin-bottom: 0.8rem;
}

.sales-contact {
  margin-top: 5rem;
  background-color: var(--light-color);
}

.sales-contact .container {
  padding: 4rem 0;
}

.sales-contact-content {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .sales-contact {
    margin-top: 3rem;
    display: flex;
    text-align: center;
  }
  
  .sales-contact-content {
    display: flex;
    flex-direction: column;
  }

  .sales-contact-content-right {
    height: 20vh;
  }

  .sales-contact {
    width: clamp();
  }
}

.sales-contact-content-right {
  width: 100%;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.8);
  background-image: url(/src/assets/Salt-Introduction.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sales-contact-content-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sales-contact-content h3 {
  color: var(--white-color);
  margin-bottom: 1.4rem;
}

.sales-contact-content button {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
  border-radius: 8px;
  border: none;
  background-color: var(--light-color);
  color: var(--white-color);
  cursor: pointer;
}

.sales-contact-content button:hover {
  background-color: var(--white-color);
  color: var(--light-color);
}

.sales-contact-content button svg {
  margin-right: 0.8rem;
  font-size: 18px;
}

.sales-contact-content-left {
  width: 100%;
  padding: 1rem 2.4rem 1rem 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--light-color);
}

.sales-contact-content-left h3 {
  color: var(--white-color);
  margin-bottom: 0.6rem;
}

.sales-contact-content-left p {
  margin-bottom: 1.4rem;
  text-align: justify;
  color: var(--dark-color);
}

.home-brands {
  margin-top: 4rem;
}

.home-brands-slider {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-slide {
  margin: 0 1.5rem;
}

.brand-slide img {
  width: 15vw;
  max-width: 150px;
}

/* ABOUT CSS */
.about-banner {
  background-image: url(../src/assets/about/about-banner.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-blend-mode: overlay;
  background-color: #2b2b2b;
}

.banner-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.banner-heading h1 {
  color: var(--white-color);
}

.about-description p {
  font-size: 1.5rem;
}

.stats-counter {
  padding: 0.8rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.counter-top {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ml-counter {
  margin-left: 0.4rem;
}

.counter-top h2 {
  font-weight: 500;
  color: var(--white-color);
}

.counter-item h3 {
  font-weight: 500;
  margin-top: 0.8rem;
  color: var(--gray-color);
}

.counter-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--light-color);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.25);
  width: 30%;
}

.certificate-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2rem;
}

.certificate-wrapper img {
  width: 15%;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.text-center p {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
}

/* QUOTE PAGE */
.quote-banner {
  background-image: url(./assets/tyBanner.jpg);
}

input {
  padding: 0.5rem 1rem;
  width: 100%;
  margin-right: 0.4rem;
  border: 1px solid #2b2b2b7e;
  border-radius: 4px;
}

input:focus {
  outline: 1px solid var(--light-color);
}

select {
  padding: 0.5rem 1rem;
  width: 100%;
  margin-right: 0.4rem;
  border: 1px solid #2b2b2b7e;
  border-radius: 4px;
}

select:focus {
  outline: 1px solid var(--light-color);
}

textarea {
  padding: 0.5rem 1rem;
  width: 100%;
  margin-right: 0.4rem;
  border: 1px solid #2b2b2b7e;
  border-radius: 4px;
  resize: none;
}

textarea:focus {
  outline: 1px solid var(--light-color);
}

.mb {
  margin-bottom: 0.8rem;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-form .container {
  display: flex;
  justify-content: space-between;
}

.contact-form-main {
  width: 100%;
  margin-right: 2rem;
}

.contact-form-info {
  width: 100%;
}

.cont-info {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.cont-info p {
  margin-left: 1rem;
}

.cont-info svg {
  color: var(--light-color);
  font-size: 22px;
}

.contact-head {
  margin-bottom: 2rem;
}

.p-width {
  width: 60%;
}

.contact-banner {
  background-image: url(../src/assets/contactBanner.jpg);
}

/* PRODUCTS CSS */
.product-banner {
  background-image: url(./assets/tyBanner.jpg);
}
.product-card-listings-main {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.product-card-listings-inner {
  margin-bottom: 0.6rem;
  margin-right: 0.6rem;
  display: flex;
  flex-direction: column;
  width: 300px;
  background-color: var(--white-color);
  padding: 1.5rem;
  border-radius: 8px;
  flex: 0 0 calc(25% - 10px);
  overflow: hidden;
  box-shadow: 2px 1px 10px 1px #d2beee;
  max-height: max-content;
}

.product-lists-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.2rem;
  padding: 1rem;
  background-color: var(--body-color);
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.1);
}

.product-det-entry {
  margin-bottom: 0.8rem;
}

.product-det-entry h4 {
  color: var(--gray-color);
}

.product-det-entry strong {
  color: var(--light-color);
}

.product-det-entry i {
  color: var(--dark-color);
}

.listed-products-img {
  width: 130px;
  object-fit: cover;
}

/* THANK YOU CSS */
.thankyou {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.thankyou a {
  color: var(--light-color);
  text-decoration: underline;
  margin-top: 1rem;
}

.thankyou p {
  font-weight: 600;
}

/* LOGISTICS CSS */
.logistics-banner {
  background-image: url(./assets/logisticsBanner.jpg);
}

.logistics-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}

@media screen and (max-width: 768px){
  .logistics-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 90%;
  }

  .log-textual {
    padding: 3rem;
  }
}

.log-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.log-img img {
  width: 450px;
  border-radius: 8px;
  box-shadow: 2px 1px 15px 1px rgba(0, 0, 0, 0.4);
}

.log-textual {
  width: 100%;
}

.log-textual h3 {
  margin-bottom: 1rem;
}
